@font-face {
    font-family: 'Karla';
    src: url('fonts/en/Karla-Regular.ttf');
}

@font-face {
    /*font-family: 'Alexandria';*/
    /*src: url('fonts/ar/Alexandria-SemiBold.ttf');*/
    /*font-display: swap;*/
    /*font-weight: 700;*/
}

@font-face {
    font-family: 'Alexandria';
    src: url('fonts/ar/Alexandria-Medium.ttf');
    font-display: swap;
    font-weight: 700;
}

@font-face {
    font-family: 'Alexandria';
    src: url('fonts/ar/Alexandria-Regular.ttf');
    font-display: swap;
    font-weight: 400;
}

html[lang*=en] body{
    font-family: Karla !important;
}

html[lang*=ar] body{
    font-family: Alexandria !important;
}

.muirtl-204u17-MuiDataGrid-main{
    min-height: 100px;
}

.MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-iconButtonContainer{
    display: none !important;
}

.MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-menuIcon{
    display: none !important;
}

.MuiTableHead-root .MuiTableCell-head{
    font-weight: bold;
}

/*.muiltr-eq27l2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected:hover{*/
/*    background-color: #0e3152 !important;*/
/*    color: #fff*/
/*}*/

.prod_table .MuiDataGrid-footerContainer{
    display:none !important;
}

.muiltr-qwkk5-MuiDataGrid-root .MuiDataGrid-cellContent{
    word-wrap: break-word !important;
    white-space: break-spaces !important;
    padding-top: 10px;
    padding-bottom: 10px;
}

.muirtl-qwkk5-MuiDataGrid-root .MuiDataGrid-cellContent{
    word-wrap: break-word !important;
    white-space: break-spaces !important;
    padding-top: 10px;
    padding-bottom: 10px;
}

.muiltr-t89xny-MuiDataGrid-columnHeaderTitle{
    word-wrap: break-word !important;
    white-space: break-spaces !important;
    padding-top: 10px;
    padding-bottom: 10px;
}

.muirtl-t89xny-MuiDataGrid-columnHeaderTitle{
    word-wrap: break-word !important;
    white-space: break-spaces !important;
    padding-top: 10px;
    padding-bottom: 10px;
}

#product-table .muiltr-t89xny-MuiDataGrid-columnHeaderTitle{
    word-wrap: unset !important;
    white-space: unset !important;
    padding-top: 10px;
    padding-bottom: 10px;
}

#product-table .muirtl-t89xny-MuiDataGrid-columnHeaderTitle{
    word-wrap: unset !important;
    white-space: unset !important;
    padding-top: 10px;
    padding-bottom: 10px;
}

.muiltr-1xhypcz-MuiStack-root{
    padding-top: unset !important;
}

.muirtl-1xhypcz-MuiStack-root{
    padding-top: unset !important;
}

#daterange-form .muiltr-1xhypcz-MuiStack-root>.MuiTextField-root, #daterange-to .muiltr-1xhypcz-MuiStack-root>.MuiTextField-root{
    width: 100%;
}

#daterange-form .muirtl-1xhypcz-MuiStack-root>.MuiTextField-root, #daterange-to .muirtl-1xhypcz-MuiStack-root>.MuiTextField-root{
    width: 100%;
}

#prev-daterange-form .muiltr-1xhypcz-MuiStack-root>.MuiTextField-root, #prev-daterange-to .muiltr-1xhypcz-MuiStack-root>.MuiTextField-root{
    width: 100%;
}

#prev-daterange-form .muirtl-1xhypcz-MuiStack-root>.MuiTextField-root, #prev-daterange-to .muirtl-1xhypcz-MuiStack-root>.MuiTextField-root{
    width: 100%;
}

.MuiDataGrid-cell{
    padding-top: 10px !important;
    padding-bottom: 10px !important;


        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
}

.MuiDataGrid-virtualScroller{
    min-height: 40px
}

.table-max-2{
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}